<template>
  <div class="rs-report__howMuchLose">
    <v-row class="align-center">
      <v-col
        class="rs-bold-highlight"
        :class="pdfType ? 'col-4' : 'col-12 col-md-4'"
      >
        <EditorSimpleText
          v-if="isEditing"
          :value="value.explanation"
          @input="emitUpdate($event, 'explanation')"
        />
        <template v-else>
          <ReportContentOutput :value="value.explanation" />
        </template>
      </v-col>
      <v-col :class="pdfType ? 'col-8' : 'col-12 col-md-8'">
        <v-hover v-slot="{ hover }">
          <div
            class="rs-report__howMuchLoseGraphic"
            :class="{ 'rs-report-section-edit ': isEditing }"
            :style="loading ? 'min-height: 350px' : null"
          >
            <ReportHowMuchLoseAnimation
              v-if="!loading"
              :pdfType="pdfType"
              :lossValue="isEditing ? '$XXX,XXX' : compValue.lossValue"
              :lossTextContent="
                compValue.lossTextContent
                  ? compValue.lossTextContent
                  : isEditing && 'Write something...'
              "
              :benefitValue="isEditing ? '$XXX,XXX' : compValue.benefit"
              :benefitTextContent="
                compValue.benefitTextContent
                  ? compValue.benefitTextContent
                  : isEditing && 'Write something...'
              "
            />
            <v-btn
              v-if="isEditing && hover"
              @click="handleShowGraphicEditorDialog()"
              elevation="10"
              color="rgba(255, 0, 255)"
              class="white--text"
              >Edit Graphic</v-btn
            >
          </div>
        </v-hover>
      </v-col>
    </v-row>

    <v-dialog v-model="showGraphicEditorDialog" width="500px" max-width="800px">
      <v-card tile>
        <v-card-title class="mb-6 text-h6">Edit Graphic Content</v-card-title>
        <v-card-text>
          <v-text-field
            :value="compValue.lossTextContent"
            label="How it works loss text"
            outlined
            hint="Use a small amount of text - about 5-6 words"
            persistent-hint
            class="mb-4"
            @input="emitUpdate($event, 'lossTextContent')"
          ></v-text-field>

          <v-text-field
            :value="compValue.benefitTextContent"
            label="How it works benefit text"
            outlined
            hint="Use a small amount of text - about 5-6 words"
            persistent-hint
            @input="emitUpdate($event, 'benefitTextContent')"
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="handleCloseGraphicEditorDialog"
            color="error"
            class="mr-4"
            >Cancel</v-btn
          >
          <v-btn
            :loading="loading"
            :disabled="loading"
            @click="handleOk"
            color="primary"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EditorSimpleText from "@/components/editors/EditorSimpleText";
import ReportContentOutput from "@/components/ReportContentOutput.vue";
import ReportHowMuchLoseAnimation from "@/components/ReportHowMuchLoseAnimation.vue";
import { objectIsEmpty } from "@/helpers";

// Component value object
export default {
  components: {
    EditorSimpleText,
    ReportContentOutput,
    ReportHowMuchLoseAnimation,
  },
  props: {
    /**
     * Value Object props
     * explanation
     * lossValue
     * lossTextContent
     * benefitValue
     * benefitTextContent
     */
    value: {
      type: Object,
      required: false,
    },
    isEditing: Boolean,
    isMobile: {
      Boolean,
    },
    pdfType: String,
    modelValue: Object,
  },
  computed: {
    compValue: {
      get: function () {
        return this.value;
      },
    },
  },
  data() {
    return {
      loading: false,
      showGraphicEditorDialog: false,
      hasGraphicEditorData: false,
      cache: null,
    };
  },
  mounted() {
    if (this.isEditing) {
      if (!this.value.lossValue) {
        this.compValue.lossValue = "{{lifetime_benefit_cut}}";
      }

      if (!this.value.benefitValue) {
        this.compValue.benefitValue =
          "{{promised_lifetime_total_benefits_post_reduction}}";
      }
    }
  },
  methods: {
    checkObjectEmpty(obj) {
      return objectIsEmpty(obj);
    },
    emitUpdate(e, property) {
      this.value[property] = e;
      this.$emit("update:value", Object.assign({}, this.value));
    },
    handleOk() {
      this.loading = true;
      this.showGraphicEditorDialog = false;
      this.$nextTick(() => {
        this.hasGraphicEditorData = false;
        this.loading = false;
      });
    },
    handleShowGraphicEditorDialog() {
      this.cache = Object.assign({}, this.value);
      this.showGraphicEditorDialog = true;
      this.hasGraphicEditorData = true;
    },
    handleCloseGraphicEditorDialog() {
      // reset forms if dialog is canceled with cached data that is set when dialog is opened
      this.$emit("update:value", Object.assign({}, this.cache));
      this.showGraphicEditorDialog = false;
      this.$nextTick(() => {
        this.hasGraphicEditorData = false;
      });
    },
  },
};
</script>
